import React, { useEffect, useState } from "react";
import { db } from "../firebaseconfig"; // Adjust the import based on your file structure
import { collection, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EventDashboard = () => {
  const [userTimings, setUserTimings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTimings = async () => {
      try {
        const userDocs = await getDocs(collection(db, "users"));
        const timingsArray = [];

        userDocs.forEach((doc) => {
          const data = doc.data();
          const teamName = data.teamName;

          // Ensure teamName is valid
          if (teamName) {
            const room1Time = data.room1?.timeTaken;
            const room2Time = data.room2?.timeTaken;
            const room3Time = data.room3?.timeTaken;

            // Check if all rooms have valid time data
            if (
              room1Time !== undefined &&
              room2Time !== undefined &&
              room3Time !== undefined
            ) {
              const totalTime = room1Time + room2Time + room3Time;

              // Push to array with valid room times
              timingsArray.push({
                teamName,
                totalTime,
                room1Time,
                room2Time,
                room3Time,
              });
            }
          }
        });

        // Sort by total time in descending order (highest first)
        timingsArray.sort((a, b) => a.totalTime - b.totalTime);

        // Set the filtered and sorted array into the state
        setUserTimings(timingsArray);
      } catch (error) {
        console.error("Error fetching timings:", error);
        toast.error("Error fetching timings.");
      } finally {
        setLoading(false);
      }
    };

    fetchTimings();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <ToastContainer position="bottom-right" />
      <h1 className="text-3xl font-bold mb-4">Event Timing Dashboard</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="w-full max-w-3xl bg-gray-800 p-8 rounded-lg shadow-lg">
          <table className="min-w-full border border-gray-700">
            <thead>
              <tr className="bg-gray-700">
                <th className="py-2 border border-gray-600">Rank</th>
                <th className="py-2 border border-gray-600">Team Name</th>
                <th className="py-2 border border-gray-600">Room 1 Time (s)</th>
                <th className="py-2 border border-gray-600">Room 2 Time (s)</th>
                <th className="py-2 border border-gray-600">Room 3 Time (s)</th>
                <th className="py-2 border border-gray-600">Total Time (s)</th>
              </tr>
            </thead>
            <tbody>
              {userTimings.length > 0 ? (
                userTimings.map((user, index) => (
                  <tr
                    key={user.teamName}
                    className={`hover:bg-gray-600 ${
                      index === 0
                        ? "bg-green-600"
                        : index === 1
                        ? "bg-blue-600"
                        : index === 2
                        ? "bg-yellow-600"
                        : ""
                    }`}
                  >
                    <td className="py-2 text-center border border-gray-600">
                      {index + 1}
                    </td>
                    <td className="py-2 text-center border border-gray-600">
                      {user.teamName}
                    </td>
                    <td className="py-2 text-center border border-gray-600">
                      {user.room1Time > 0 ? user.room1Time : "-"}
                    </td>
                    <td className="py-2 text-center border border-gray-600">
                      {user.room2Time > 0 ? user.room2Time : "-"}
                    </td>
                    <td className="py-2 text-center border border-gray-600">
                      {user.room3Time > 0 ? user.room3Time : "-"}
                    </td>
                    <td className="py-2 text-center border border-gray-600">
                      {user.totalTime > 0 ? user.totalTime : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="text-center py-2 border border-gray-600"
                  >
                    No timings available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EventDashboard;
