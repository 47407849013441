import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (!email || !password) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Login successful!");
      navigate("/dashboard");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRegisterRedirect = () => {
    navigate("/register");
  };

  return (
    <div className="bg-gray-900 h-screen flex items-center justify-center">
      <ToastContainer />
      <div className="bg-gray-800 p-10 rounded-3xl shadow-2xl w-96">
        <h1 className="text-4xl font-bold text-white text-center">Login</h1>
        <form
          className="mt-5 flex flex-col items-center"
          onSubmit={handleLogin}
        >
          <div className="relative mb-3 w-full">
            <span className="absolute left-3 top-2 text-gray-400">
              <i className="fas fa-envelope"></i>
            </span>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-2 border-gray-600 rounded-full px-10 py-2 w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
            />
          </div>
          <div className="relative mb-3 w-full">
            <span className="absolute left-3 top-2 text-gray-400">
              <i className="fas fa-lock"></i>
            </span>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-2 border-gray-600 rounded-full px-10 py-2 w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
            />
          </div>
          <button className="bg-indigo-600 hover:bg-indigo-700 text-white text-md font-semibold rounded-full px-7 py-2 w-full transition duration-200 transform hover:scale-105">
            Login
          </button>
        </form>
        <p className="mt-5 text-gray-300">
          Don't have an account?{" "}
          <button
            onClick={handleRegisterRedirect}
            className="text-indigo-400 underline"
          >
            Register here
          </button>
        </p>
      </div>
    </div>
  );
}

export default Login;
