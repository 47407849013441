// import { LuMoveUpRight } from "react-icons/lu";
// import { useNavigate } from "react-router-dom";

// function Home() {
//   const navigate = useNavigate();

//   const handleNavigate = () => {
//     navigate("/login");
//   };

//   const handleStart = () => {
//     navigate("/dashboard");
//   };

//   return (
//     <div className="h-screen flex flex-col relative">
//       {/* Background Image */}
//       <img
//         src="https://c4.wallpaperflare.com/wallpaper/275/827/774/architecture-artistic-buildings-cabin-wallpaper-preview.jpg"
//         alt="Background"
//         className="absolute inset-0 w-full h-full object-cover"
//       />
//       <div className="flex justify-end mr-5 pt-5 relative z-10">
//         <button
//           className="bg-white bg-opacity-80 border-2 border-black rounded-full px-7 py-2 flex flex-row items-center"
//           onClick={handleNavigate}
//         >
//           login
//         </button>
//       </div>
//       <div className="flex items-center justify-center flex-grow relative z-10">
//         <div className="flex flex-col items-center bg-white bg-opacity-15 p-5 rounded-lg">
//           <h1 className="text-4xl font-bold text-black">Escape room</h1>
//           <button
//             className="mt-10 border-2 border-black rounded-full px-7 py-2 flex flex-row items-center"
//             onClick={handleStart}
//           >
//             ENTER <LuMoveUpRight />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;

import { LuMoveUpRight } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/login");
  };

  const handleStart = () => {
    navigate("/dashboard");
  };

  return (
    <div className="h-screen flex flex-col relative">
      {/* Background Image */}
      <img
        src="https://c4.wallpaperflare.com/wallpaper/275/827/774/architecture-artistic-buildings-cabin-wallpaper-preview.jpg"
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
      />

      <div className="flex justify-end mr-5 pt-5 relative z-10">
        <button
          className="bg-white bg-opacity-50 hover:bg-opacity-70 border-4 border-black rounded-full px-7 py-2 flex flex-row items-center font-bold text-gray-200 bg-gradient-to-r from-gray-600 to-gray-400 hover:bg-gradient-to-r hover:from-gray-600 hover:to-green-950 transition-all duration-500 ease-in-out"
          onClick={handleNavigate}
        >
          login
        </button>
      </div>

      <div className="flex items-center justify-center flex-grow relative z-10">
        <div className="flex flex-col items-center bg-white bg-opacity-15 p-5 rounded-lg">
          <h1 className="text-4xl font-bold text-gray-400">Escape room</h1>
          <button
            className="mt-10 border-3  rounded-full px-10 py-3 flex flex-row items-center font-bold text-xl text-black bg-gradient-to-r from-gray-600 to-gray-400 hover:bg-gradient-to-r hover:from-gray-200 hover:to-green-950 transition-all duration-500 ease-in-out"
            onClick={handleStart}
          >
            ENTER <LuMoveUpRight className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
