import React, { useState, useEffect } from "react";
import {
  db,
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "../firebaseconfig"; // Ensure this is correctly configured for your Firebase
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Define binary questions for letters A to H
function getRandomLetter() {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const randomIndex = Math.floor(Math.random() * letters.length);
  return letters[randomIndex];
}

function getBinaryForLetter(letter) {
  return letter.charCodeAt(0).toString(2).padStart(8, "0");
}

const generateRandomQuestions = (numQuestions) => {
  const questions = [];
  const usedLetters = new Set(); // To avoid duplicate letters

  while (questions.length < numQuestions) {
    const letter = getRandomLetter();

    if (!usedLetters.has(letter)) {
      usedLetters.add(letter); // Ensure unique letters
      questions.push({
        letter: letter,
        expectedOutput: getBinaryForLetter(letter),
      });
    }
  }

  return questions;
};

const questions = generateRandomQuestions(15);

const BinaryQuiz = () => {
  const [userAnswers, setUserAnswers] = useState(Array(8).fill("")); // Array to hold user answers for each bit
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer,setTimer] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [secretKeys, setSecretKeys] = useState([]); // Stores accumulated secret keys
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        navigate("/login");
      }
    });

    let interval;
    if (!isComplete) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      unsubscribe(); // Clean up the auth listener
    };
  }, [isComplete, navigate]);

  const handleInputChange = (index, value) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = value;
    setUserAnswers(updatedAnswers);
  };

  const handleSubmitAnswer = async () => {
    const userAnswer = userAnswers.join("");
    const isCorrect = userAnswer === currentQuestion.expectedOutput;

    if (isCorrect) {
      const newSecretKey = `${currentQuestion.letter}`;
      setSecretKeys((prev) => [...prev, newSecretKey]);
      toast.success(
        `Correct! The binary value of ${currentQuestion.letter} is ${currentQuestion.expectedOutput}.`
      );

      // Check if there are more questions to show
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setUserAnswers(Array(8).fill("")); // Clear the inputs for the next question
      } else {
        toast.success("All questions completed!");
        setIsComplete(true);
        // Automatically submit quiz results after completing
        await handleSubmitQuiz();
      }
    } else {
      toast.error(`Incorrect answer!`);
    }
  };

  const handleSubmitQuiz = async () => {
    if (!userId) {
      toast.error("User ID not found. Please log in again.");
      return;
    }

    try {
      // Reference to the user's document in the users collection
      const userDocRef = doc(db, "users", userId);

      // Check if the document exists
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        // Document exists, update the time taken for room2
        await updateDoc(userDocRef, {
          room2: {
            timeTaken: timer,
            timestamp: new Date(),
            secretKeys, // Include secret keys if needed
          },
        });
      } else {
        // Document does not exist, create it with the time taken
        await setDoc(userDocRef, {
          room2: {
            timeTaken: timer,
            timestamp: new Date(),
            secretKeys, // Include secret keys if needed
          },
        });
      }

      toast.success("Quiz submitted successfully!");
    } catch (error) {
      console.error("Error saving quiz results:", error);
      toast.error("Error submitting quiz results.");
    }
  };

  const handleRedirect = () => {
    navigate("/room3");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white relative">
      <ToastContainer position="bottom-right" />

      {/* Timer Display */}
      <div className="absolute top-4 right-4 bg-gray-700 text-white px-4 py-2 rounded-md">
        <p className="text-xl font-semibold">{timer} seconds</p>
      </div>

      <div className="w-full max-w-3xl bg-gray-800 p-8 shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold text-center mb-6">Binary Quiz</h1>

        {!isComplete ? (
          <div className="flex flex-col items-center">
            <p className="font-medium mb-4">
              Enter the binary value for the letter {currentQuestion.letter}:
            </p>
            <div className="flex space-x-2 mb-4">
              {userAnswers.map((answer, index) => (
                <input
                  key={index}
                  type="text"
                  value={answer}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className="w-12 h-12 text-center p-2 border border-gray-600 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="0 or 1"
                  required
                  maxLength={1} // Limit input to 1 character for binary digits
                />
              ))}
            </div>
            <button
              onClick={handleSubmitAnswer}
              className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md"
            >
              Submit Answer
            </button>

            {/* Display Secret Keys after each correct answer */}
            <div className="mt-6">
              {secretKeys.length > 0 && (
                <div className="bg-gray-700 p-4 rounded-md">
                  <p className="text-green-400 font-semibold">
                    Accumulated Secret Keys:
                  </p>
                  <p className="text-gray-200">{secretKeys.join(", ")}</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="text-green-400 text-center text-xl mt-6">
              Quiz completed in {timer} seconds!
            </p>
            <p className="text-lg mt-4">Final Secret Keys:</p>
            <p className="text-gray-400">{secretKeys.join(", ")}</p>
            <button
              onClick={handleRedirect}
              className="mt-6 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md"
            >
              Proceed to Room 3
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BinaryQuiz;
