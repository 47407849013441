import React, { useState, useEffect } from "react";
import {
  db,
  addDoc,
  collection,
  setDoc,
  updateDoc,
  doc,
  getDoc,
} from "../firebaseconfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const questions = [
  {
    id: 1,
    question: "Write an SQL query to get all users from the 'users' table.",
    answer: "SELECT * FROM users;",
    output: [
      { id: 1, name: "Alice", email: "alice@example.com" },
      { id: 2, name: "Bob", email: "bob@example.com" },
      { id: 3, name: "Charlie", email: "charlie@example.com" },
    ],
    secretKey: "QUERY1",
  },
  {
    id: 2,
    question:
      "Write an SQL query to fetch the user with the email 'alice@example.com'.",
    answer: "SELECT * FROM users WHERE email='alice@example.com';",
    output: [{ id: 1, name: "Alice", email: "alice@example.com" }],
    secretKey: "QUERY2",
  },
  {
    id: 3,
    question:
      "Write an SQL query to count the total number of users in the 'users' table.",
    answer: "SELECT COUNT(*) FROM users;",
    output: [{ count: 3 }],
    secretKey: "QUERY3",
  },
  {
    id: 4,
    question:
      "Write an SQL query to fetch all users who are older than 18 years from the 'users' table.",
    answer: "SELECT * FROM users WHERE age > 18;",
    output: [
      { id: 1, name: "Alice", email: "alice@example.com", age: 22 },
      { id: 2, name: "Bob", email: "bob@example.com", age: 20 },
    ],
    secretKey: "QUERY4",
  },
  {
    id: 5,
    question:
      "Write an SQL query to fetch the names of all users from the 'users' table.",
    answer: "SELECT name FROM users;",
    output: [{ name: "Alice" }, { name: "Bob" }, { name: "Charlie" }],
    secretKey: "QUERY5",
  },
  {
    id: 6,
    question:
      "Write an SQL query to fetch the second highest age from the 'users' table.",
    answer:
      "SELECT MAX(age) FROM users WHERE age < (SELECT MAX(age) FROM users);",
    output: [{ age: 20 }],
    secretKey: "QUERY6",
  },
  {
    id: 7,
    question:
      "Write an SQL query to fetch all users whose names start with the letter 'A' from the 'users' table.",
    answer: "SELECT * FROM users WHERE name LIKE 'A%';",
    output: [{ id: 1, name: "Alice", email: "alice@example.com" }],
    secretKey: "QUERY7",
  },
  {
    id: 8,
    question:
      "Write an SQL query to fetch users along with their registration date, and order them by the registration date in descending order.",
    answer: "SELECT * FROM users ORDER BY registration_date DESC;",
    output: [
      {
        id: 3,
        name: "Charlie",
        email: "charlie@example.com",
        registration_date: "2024-09-01",
      },
      {
        id: 2,
        name: "Bob",
        email: "bob@example.com",
        registration_date: "2024-08-15",
      },
      {
        id: 1,
        name: "Alice",
        email: "alice@example.com",
        registration_date: "2024-07-30",
      },
    ],
    secretKey: "QUERY8",
  },
];

const SQLQuiz = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [timer, setTimer] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [secretKeys, setSecretKeys] = useState([]);
  const [enteredKeys, setEnteredKeys] = useState(
    Array(questions.length).fill("")
  );
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        navigate("/login");
      }
    });

    let interval;
    if (!isComplete) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      unsubscribe(); // Clean up the auth listener
    };
  }, [isComplete, navigate]);

  const handleInputChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const handleSubmitAnswer = () => {
    const normalizedUserAnswer = userAnswer
      .trim()
      .replace(/"/g, "'")
      .toLowerCase();

    const normalizedCorrectAnswer = currentQuestion.answer
      .trim()
      .replace(/"/g, "'")
      .toLowerCase();

    if (normalizedUserAnswer === normalizedCorrectAnswer) {
      console.log("Answers match!");
      alert("Correct answer! Click 'Next' to continue."); // Use alert for correct answer
      setIsCorrect(true);
      setShowOutput(true);
      setSecretKeys((prev) => [...prev, currentQuestion.secretKey]);
    } else {
      console.log("Answers do not match!");
      alert("Incorrect answer! Please try again."); // Use alert for incorrect answer
      setIsCorrect(false);
      setShowOutput(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setUserAnswer("");
      setIsCorrect(false);
      setShowOutput(false);
    } else {
      setIsComplete(true);
      handleSubmitQuiz();
    }
  };

  const handleSubmitQuiz = async () => {
    // Check if the user ID is available
    if (!userId) {
      alert("User ID not found. Please log in again.");
      return;
    }

    try {
      // Reference to the user's document in the users collection
      const userDocRef = doc(db, "users", userId);

      // Check if the document exists
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        // Document exists, update the time taken for room1
        await updateDoc(userDocRef, {
          room1: {
            timeTaken: timer,
            timestamp: new Date(),
            secretKeys,
          },
        });
      } else {
        // Document does not exist, create it with the time taken
        await setDoc(userDocRef, {
          room1: {
            timeTaken: timer,
            timestamp: new Date(),
            secretKeys,
          },
        });
      }

      alert("Quiz results stored successfully!");
    } catch (error) {
      console.error("Error storing quiz results:", error);
      alert("Error submitting quiz results.");
    }
  };

  const handleKeyChange = (index, value) => {
    const newKeys = [...enteredKeys];
    newKeys[index] = value;
    setEnteredKeys(newKeys);
  };

  const checkSecretKeys = () => {
    const allKeysCorrect = enteredKeys.every(
      (key, index) => key === questions[index].secretKey
    );

    if (allKeysCorrect) {
      navigate("/room2");
    } else {
      alert("One or more secret keys are incorrect! Please try again.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white relative">
      <ToastContainer position="bottom-right" />

      {/* Timer Display */}
      <div className="absolute top-4 right-4 bg-gray-700 text-white px-4 py-2 rounded-md">
        <p className="text-xl font-semibold">{timer} seconds</p>
      </div>

      <div className="w-full max-w-3xl bg-gray-800 p-8 shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-center flex-grow">SQL Quiz</h1>
        </div>

        {!isComplete ? (
          <div className="flex flex-col space-y-4">
            <p className="font-medium">{currentQuestion.question}</p>

            {/* Input field for SQL command */}
            <input
              type="text"
              value={userAnswer}
              onChange={handleInputChange}
              className="p-2 border border-gray-600 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Write your SQL command here"
              required
            />
            {!isCorrect && (
              <button
                onClick={handleSubmitAnswer}
                className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-md"
              >
                Submit Answer
              </button>
            )}

            {/* Display output table and secret key only when the answer is correct */}
            {showOutput && (
              <div className="overflow-x-auto mt-4">
                <table className="min-w-full bg-gray-700 border border-gray-600">
                  <thead>
                    <tr className="bg-gray-600">
                      {currentQuestion.output[0]?.id && (
                        <th className="border border-gray-600 p-2">ID</th>
                      )}
                      {currentQuestion.output[0]?.name && (
                        <th className="border border-gray-600 p-2">Name</th>
                      )}
                      {currentQuestion.output[0]?.email && (
                        <th className="border border-gray-600 p-2">Email</th>
                      )}
                      {currentQuestion.output[0]?.age && (
                        <th className="border border-gray-600 p-2">Age</th>
                      )}
                      {currentQuestion.output[0]?.count && (
                        <th className="border border-gray-600 p-2">Count</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentQuestion.output.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-600">
                        {item.id && (
                          <td className="border border-gray-600 p-2">
                            {item.id}
                          </td>
                        )}
                        {item.name && (
                          <td className="border border-gray-600 p-2">
                            {item.name}
                          </td>
                        )}
                        {item.email && (
                          <td className="border border-gray-600 p-2">
                            {item.email}
                          </td>
                        )}
                        {item.age && (
                          <td className="border border-gray-600 p-2">
                            {item.age}
                          </td>
                        )}
                        {item.count && (
                          <td className="border border-gray-600 p-2">
                            {item.count}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className="text-gray-400 mt-2">
                  Secret Key: {currentQuestion.secretKey}
                </p>
                <p className="text-red-500 mt-2">
                  ⚠ Note down the secret key for future reference!
                </p>
              </div>
            )}

            {/* Next Question Button */}
            {isCorrect && (
              <button
                onClick={handleNextQuestion}
                className="mt-4 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md"
              >
                Next Question
              </button>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="text-green-400 text-center text-xl mt-6">
              Quiz completed in {timer} seconds!
            </p>
            <p className="text-center mt-4">Please enter your secret keys:</p>
            {questions.map((_, index) => (
              <input
                key={index}
                type="text"
                value={enteredKeys[index]}
                onChange={(e) => handleKeyChange(index, e.target.value)}
                className="mt-2 p-2 border border-gray-600 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder={`Enter secret key ${index + 1}`}
                required
              />
            ))}
            <button
              onClick={checkSecretKeys}
              className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md"
            >
              Submit Secret Keys
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SQLQuiz;
