import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./home";
import Login from "./login";
import Register from "./register";
import Dashboard from "./dashboard";
import Room1 from "./room1";
import Room2 from "./room2";
import Room3 from "./room3";
import MasterDashboard from "./masterdashboard";
import { AnimatePresence } from "framer-motion";
// import Room3 from "./room3";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <>
      <AnimatePresence>
        <Routes loaction={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/room1" element={<Room1 />} />
          <Route path="/room2" element={<Room2 />} />
          <Route path="/room3" element={<Room3 />} />
          <Route path="/master-dashboard" element={<MasterDashboard />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default AnimatedRoutes;
