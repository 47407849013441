// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { getAuth } from "firebase/auth";
// import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function Dashboard() {
//   const navigate = useNavigate();
//   const auth = getAuth();
//   const db = getFirestore();
//   const [teamName, setTeamName] = useState("");
//   const [canStart, setCanStart] = useState(false);
//   const [existingTeamName, setExistingTeamName] = useState("");
//   const [showCards, setShowCards] = useState(false);

//   useEffect(() => {
//     const user = auth.currentUser;
//     if (!user) {
//       navigate("/login");
//     } else {
//       const fetchPermission = async () => {
//         try {
//           const permissionDoc = await getDoc(doc(db, "permission", "start"));
//           if (permissionDoc.exists()) {
//             setCanStart(permissionDoc.data().canStart);
//           } else {
//             console.log("No such document!");
//           }
//         } catch (error) {
//           console.error("Error fetching permission:", error);
//         }
//       };

//       const fetchTeamName = async (uid) => {
//         try {
//           const userDoc = await getDoc(doc(db, "users", uid));
//           if (userDoc.exists()) {
//             const data = userDoc.data();
//             if (data.teamName) {
//               setExistingTeamName(data.teamName);
//               setShowCards(true);
//             }
//           }
//         } catch (error) {
//           console.error("Error fetching team name:", error);
//         }
//       };

//       fetchPermission();
//       fetchTeamName(user.uid);
//     }
//   }, [auth, navigate, db]);

//   const handleLogout = async () => {
//     try {
//       await auth.signOut();
//       navigate("/login");
//     } catch (error) {
//       console.error("Logout error:", error);
//     }
//   };

//   const handleSaveTeamName = async () => {
//     const user = auth.currentUser;
//     if (user) {
//       try {
//         await updateDoc(doc(db, "users", user.uid), { teamName });
//         toast.success("Team name saved successfully!");
//         setExistingTeamName(teamName);
//         setTeamName("");
//         setShowCards(true);
//       } catch (error) {
//         console.error("Error updating team name:", error);
//       }
//     }
//   };

//   const handleCardClick = (index) => {
//     if (canStart) {
//       switch (index) {
//         case 1:
//           navigate("/room1");
//           break;
//         case 2:
//           navigate("/room2");
//           break;
//         case 3:
//           navigate("/room3");
//           break;
//         default:
//           break;
//       }
//     } else {
//       toast.warning("Please wait for the host to start the event");
//     }
//   };

//   return (
//     <div className="bg-gray-900 h-screen flex flex-col relative">
//       <ToastContainer />
//       <div className="flex justify-end p-5">
//         <button
//           onClick={handleLogout}
//           className="border-2 border-gray-400 text-gray-200 rounded-full px-4 font-bold py-2 hover:bg-gray-800 transition duration-200"
//         >
//           Logout
//         </button>
//       </div>
//       <h1 className="text-4xl font-extrabold text-white text-center mt-10">
//         Welcome to the Dashboard!
//       </h1>
//       <div className="flex flex-col items-center justify-center flex-grow">
//         {existingTeamName && !canStart && (
//           <p className="mt-5 text-gray-300">
//             Waiting for the host to start the event...
//           </p>
//         )}
//         {!existingTeamName && canStart && (
//           <>
//             <p className="mt-5 text-gray-300">The Host started the event!</p>
//             <input
//               type="text"
//               placeholder="Enter Team Name"
//               value={teamName}
//               onChange={(e) => setTeamName(e.target.value)}
//               className="border-2  border-gray-600 rounded-full px-4 py-2 mb-3 mt-5 w-64 bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
//             />
//             <button
//               onClick={handleSaveTeamName}
//               className="border-2 border-gray-400 text-gray-200 font-bold rounded-full px-7 py-2 mt-2 bg-gray-700 hover:bg-gray-600 transition duration-200"
//             >
//               Save Team Name
//             </button>
//           </>
//         )}
//         {!existingTeamName && !canStart && (
//           <>
//             <input
//               type="text"
//               placeholder="Enter Team Name"
//               value={teamName}
//               onChange={(e) => setTeamName(e.target.value)}
//               className="border-2 border-gray-600 rounded-full px-4 py-2 mb-3 mt-5 w-64 bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
//             />
//             <button
//               onClick={handleSaveTeamName}
//               className="border-2 border-gray-600 rounded-full px-7 py-2 mt-2 bg-gray-700 hover:bg-gray-600 transition duration-200"
//             >
//               Save Team Name
//             </button>
//           </>
//         )}
//         {showCards && (
//           <div className="flex flex-row mt-10">
//             {[1, 2, 3].map((index) => (
//               <div
//                 key={index}
//                 className="border-2 border-gray-600 rounded-lg m-3 p-5 w-64 bg-gray-800 cursor-pointer transition duration-200 hover:bg-gray-700"
//                 onClick={() => handleCardClick(index)}
//               >
//                 <h2 className="text-xl text-white font-bold">Room {index}</h2>
//                 <p className="text-gray-300">This is Room number {index}</p>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dashboard() {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const [teamName, setTeamName] = useState("");
  const [canStart, setCanStart] = useState(false);
  const [existingTeamName, setExistingTeamName] = useState("");
  const [showCards, setShowCards] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate("/login");
    } else {
      const fetchPermission = async () => {
        try {
          const permissionDoc = await getDoc(doc(db, "permission", "start"));
          if (permissionDoc.exists()) {
            setCanStart(permissionDoc.data().canStart);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching permission:", error);
        }
      };

      const fetchTeamName = async (uid) => {
        try {
          const userDoc = await getDoc(doc(db, "users", uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            if (data.teamName) {
              setExistingTeamName(data.teamName);
              setShowCards(true);
            }
          }
        } catch (error) {
          console.error("Error fetching team name:", error);
        }
      };

      fetchPermission();
      fetchTeamName(user.uid);
    }
  }, [auth, navigate, db]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleSaveTeamName = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await updateDoc(doc(db, "users", user.uid), { teamName });
        toast.success("Team name saved successfully!");
        setExistingTeamName(teamName);
        setTeamName("");
        setShowCards(true);
      } catch (error) {
        console.error("Error updating team name:", error);
      }
    }
  };

  const handleCardClick = (index) => {
    if (canStart) {
      switch (index) {
        case 1:
          navigate("/room1");
          break;
        case 2:
          navigate("/room2");
          break;
        case 3:
          navigate("/room3");
          break;
        default:
          break;
      }
    } else {
      toast.warning("Please wait for the host to start the event");
    }
  };

  // Room descriptions
  const roomDescriptions = {
    1: "In this room, we are going to tackle SQL problems.",
    2: "This room is dedicated to solve the Binary problem",
    3: "In this room, we will explore frontend frameworks and libraries.",
  };

  return (
    <div className="bg-gray-900 h-screen flex flex-col relative">
      <ToastContainer />
      <div className="flex justify-end p-5">
        <button
          onClick={handleLogout}
          className="border-2 border-gray-400 text-gray-200 rounded-full px-4 font-bold py-2 hover:bg-gray-800 transition duration-200"
        >
          Logout
        </button>
      </div>
      <h1 className="text-4xl font-extrabold text-white text-center mt-10">
        Welcome to the Dashboard!
      </h1>
      <div className="flex flex-col items-center justify-center flex-grow">
        {existingTeamName && !canStart && (
          <p className="mt-5 text-gray-300">
            Waiting for the host to start the event...
          </p>
        )}
        {!existingTeamName && canStart && (
          <>
            <p className="mt-5 text-gray-300">The Host started the event!</p>
            <input
              type="text"
              placeholder="Enter Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              className="border-2 border-gray-600 rounded-full px-4 py-2 mb-3 mt-5 w-64 bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            />
            <button
              onClick={handleSaveTeamName}
              className="border-2 border-gray-400 text-gray-200 font-bold rounded-full px-7 py-2 mt-2 bg-gray-700 hover:bg-gray-600 transition duration-200"
            >
              Save Team Name
            </button>
          </>
        )}
        {!existingTeamName && !canStart && (
          <>
            <input
              type="text"
              placeholder="Enter Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              className="border-2 border-gray-600 rounded-full px-4 py-2 mb-3 mt-5 w-64 bg-gray-800 text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            />
            <button
              onClick={handleSaveTeamName}
              className="border-2 border-gray-600 rounded-full px-7 py-2 mt-2 bg-gray-700 hover:bg-gray-600 transition duration-200"
            >
              Save Team Name
            </button>
          </>
        )}
        {showCards && (
          <div className="flex flex-row mt-10">
            {[1, 2, 3].map((index) => (
              <div
                key={index}
                className="border-2 border-gray-600 rounded-lg m-3 p-5 w-64 bg-gray-800 cursor-pointer transition duration-200 hover:bg-gray-700"
                onClick={() => handleCardClick(index)}
              >
                <h2 className="text-xl text-white font-bold">Room {index}</h2>
                <p className="text-gray-300">{roomDescriptions[index]}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
