import React, { useState } from "react";
import { registerUser } from "./auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loader state

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true); // Start loading

    try {
      await registerUser(email, password, username);
      toast.success("User registered successfully");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1500); // Simulate delay before redirecting
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false); // Stop loading after completion
    }
  };

  return (
    <div className="bg-gray-900 h-screen flex items-center justify-center">
      <ToastContainer />
      <div className="bg-gray-800 p-10 rounded-3xl shadow-2xl w-96">
        <h1 className="text-4xl font-bold text-white text-center">Register</h1>
        <form
          className="mt-5 flex flex-col items-center"
          onSubmit={handleRegister}
        >
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border-2 border-gray-600 rounded-full px-10 py-2 mb-3 w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border-2 border-gray-600 rounded-full px-10 py-2 mb-3 w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border-2 border-gray-600 rounded-full px-10 py-2 mb-3 w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-700 transition duration-200"
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="border-2 border-gray-600 rounded-full px-10 py-2 mb-3 w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-700 transition duration-200"
          />

          {/* Disable button and show loader during registration */}
          <button
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-full px-7 py-2 w-full transition duration-200 transform hover:scale-105"
            disabled={loading}
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </form>

        {/* Loader message */}
        {loading && (
          <div className="text-center mt-5 text-white">
            Processing your request...
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
