import React, { useState, useEffect } from "react";
import { db, addDoc, collection, doc, setDoc, updateDoc, getDoc } from "../firebaseconfig"; // Ensure this is correctly configured for your Firebase
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const questions = [
  {
    id: 1,
    question:
      "Debug the following Python code to generate a list of prime numbers up to a given number. Enter the output",
    code: `def primes_up_to(n):\n    primes = []\n    for num in range(2, n + 1):\n        is_prime = True\n        for i in range(2, num + 1):\n            if num % i == 0:\n                is_prime = False\n                break\n        if is_prime:\n            primes.append(num)\n    return primes\n\nprint(primes_up_to(10))`,
    expectedOutput: [2, 3, 5, 7],
    secretKey: "PRIMES",
  },
  {
    id: 2,
    question:
      "Debug the following Python code to flatten a nested list. Enter the output",
    code: `def flatten(lst):\n    flat_list = []\n    for item in lst:\n        if isinstance(item, list):\n            flat_list.extend(flatten(item))\n        else:\n            flat_list.append(item)\n    return flat_list\n\nprint(flatten([1, [2, [3, 4]], 5, [6]]))`,
    expectedOutput: [1, 2, 3, 4, 5, 6],
    secretKey: "FLATTEN",
  },
  {
    id: 3,
    question:
      "Debug the following Python code to find the maximum element in a list. Enter the output",
    code: `def find_max(lst):\n    max_value = lst[0]\n    for num in lst:\n        if num > max_value:\n            max_value = num\n        elif num == max_value:\n            continue\n    return max_value\n\nprint(find_max([3, 1, 4, 1, 5, 9, 5]))`,
    expectedOutput: 9,
    secretKey: "MAXIMUM",
  },
  {
    id: 4,
    question:
      "Debug the following Python code to remove vowels from a string. Enter the output",
    code: `def remove_vowels(s):\n    vowels = 'aeiouAEIOU'\n    return ''.join([char for char in s if char not in vowels])\n\nprint(remove_vowels('Hello World!'))`,
    expectedOutput: "Hll Wrld!",
    secretKey: "VOWELS",
  },
  {
    id: 5,
    question:
      "Debug the following Python code to reverse a string. Enter the output",
    code: `def reverse_string(s):\n    return s[::-1]\n\nprint(reverse_string('Python!'))`,
    expectedOutput: "!nohtyP",
    secretKey: "REVERSE",
  },
  {
    id: 6,
    question:
      "Debug the following Python code to check if a string is a palindrome. Enter the output",
    code: "def is_palindrome(s):\n    return s == s[::-1]\n\nprint(is_palindrome('racecar!'))",
    expectedOutput: false,
    secretKey: "PALINDROME",
  },
  {
    id: 7,
    question:
      "Debug the following Python code to generate the Fibonacci sequence up to a given number. Enter the output",
    code: "def fibonacci(n):\n    fib_seq = [0, 1]\n    while len(fib_seq) <= n:\n        fib_seq.append(fib_seq[-1] + fib_seq[-2])\n    return fib_seq\n\nprint(fibonacci(5))",
    expectedOutput: [0, 1, 1, 2, 3],
    secretKey: "FIBONACCI",
  },
  {
    id: 8,
    question:
      "Debug the following Python code to calculate the factorial of a number. Enter the output",
    code: "def factorial(n):\n    result = 1\n    for i in range(1, n):\n        result *= i\n    return result\n\nprint(factorial(5))",
    expectedOutput: 120,
    secretKey: "FACTORIAL",
  },
  {
    id: 9,
    question:
      "Debug the following Python code to find the sum of a list of numbers. Enter the output",
    code: "def sum_of_list(lst):\n    total = 1\n    for num in lst:\n        total += num\n    return total\n\nprint(sum_of_list([1, 2, 3, 4, 5]))",
    expectedOutput: 15,
    secretKey: "SUM",
  },
  {
    id: 10,
    question:
      "Debug the following Python code to count the occurrences of a specific character in a string. Enter the output",
    code: "def count_char(s, char):\n    count = 0\n    for c in s:\n        if c.lower() == char:\n            count += 1\n    return count\n\nprint(count_char('banana', 'a'))",
    expectedOutput: 3,
    secretKey: "CHAR_COUNT",
  },
];





const PythonDebugQuiz = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [timer, setTimer] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [secretKeys, setSecretKeys] = useState([]);
  const [enteredKeys, setEnteredKeys] = useState(
    Array(questions.length).fill("")
  );
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        navigate("/login");
      }
    });

    let interval;
    if (!isComplete) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      unsubscribe(); // Clean up the auth listener
    };
  }, [isComplete, navigate]);

  const handleInputChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const handleSubmitAnswer = () => {
    try {
      // Evaluate user input as Python code (assuming it is safe to do so)
      const userOutput = eval(userAnswer);
      if (
        JSON.stringify(userOutput) ===
        JSON.stringify(currentQuestion.expectedOutput)
      ) {
        toast.success("Correct answer! Click 'Next' to continue.");
        setIsCorrect(true);
        setShowOutput(true);
        setSecretKeys((prev) => [...prev, currentQuestion.secretKey]);
      } else {
        toast.error("Incorrect answer! Please try again.");
        setIsCorrect(false);
        setShowOutput(false);
      }
    } catch (error) {
      toast.error("Error executing your code! Please debug.");
      setIsCorrect(false);
      setShowOutput(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setUserAnswer("");
      setIsCorrect(false);
      setShowOutput(false);
    } else {
      setIsComplete(true);
      handleSubmitQuiz();
    }
  };

  const handleSubmitQuiz = async () => {
    if (!userId) {
      toast.error("User ID not found. Please log in again.");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        await updateDoc(userDocRef, {
          room3: {
            timeTaken: timer,
            timestamp: new Date(),
          },
        });
      } else {
        await setDoc(userDocRef, {
          room3: {
            timeTaken: timer,
            timestamp: new Date(),
          },
        });
      }
      toast.success("Quiz submitted successfully!");
    } catch (error) {
      console.error("Error saving quiz results:", error);
      toast.error("Error submitting quiz results.");
    }
  };

  const handleKeyChange = (index, value) => {
    const newKeys = [...enteredKeys];
    newKeys[index] = value;
    setEnteredKeys(newKeys);
  };

  const checkSecretKeys = () => {
    if (enteredKeys.length < questions.length) {
      toast.error("Please enter all secret keys!");
      return;
    }

    const allKeysCorrect = enteredKeys.every(
      (key, index) => key === questions[index]?.secretKey // Use optional chaining
    );
    if (allKeysCorrect) {
      navigate("/master-dashboard");
    } else {
      toast.error("One or more secret keys are incorrect! Please try again.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white relative">
      <ToastContainer position="bottom-right" />

      {/* Timer Display */}
      <div className="absolute top-4 right-4 bg-gray-700 text-white px-4 py-2 rounded-md">
        <p className="text-xl font-semibold">{timer} seconds</p>
      </div>

      <div className="w-full max-w-3xl bg-gray-800 p-8 shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-center flex-grow">
            Python Debug Quiz
          </h1>
        </div>

        {!isComplete ? (
          <div className="flex flex-col space-y-4">
            <p className="font-medium">{currentQuestion.question}</p>
            <pre className="bg-gray-700 p-4 rounded-md overflow-x-auto">
              {currentQuestion.code}
            </pre>

            {/* Input field for user debugging the Python code */}
            <textarea
              value={userAnswer}
              onChange={handleInputChange}
              className="p-2 border border-gray-600 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Paste your debugged Python code here"
              rows={5}
              required
            />
            {!isCorrect && (
              <button
                onClick={handleSubmitAnswer}
                className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-md"
              >
                Submit Output
              </button>
            )}

            {/* Display secret key only when the answer is correct */}
            {showOutput && (
              <div className="mt-4">
                <p className="text-gray-400">
                  Secret Key: {currentQuestion.secretKey}
                </p>
                <p className="text-red-500 mt-2">
                  ⚠️ Note down the secret key for future reference!
                </p>
              </div>
            )}

            {/* Next Question Button */}
            {isCorrect && (
              <button
                onClick={handleNextQuestion}
                className="mt-4 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md"
              >
                Next Question
              </button>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <p className="text-green-400 text-center text-xl mt-6">
              Quiz completed in {timer} seconds!
            </p>
            <p className="text-lg mt-4">Enter your secret keys:</p>
            {questions.map((_, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={enteredKeys[index]}
                  onChange={(e) => handleKeyChange(index, e.target.value)}
                  className="p-2 border border-gray-600 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder={`Secret Key ${index + 1}`}
                />
              </div>
            ))}
            <button
              onClick={checkSecretKeys}
              className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md"
            >
              Submit Secret Keys
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PythonDebugQuiz;
